import React from 'react';
import moment from 'moment-timezone';
import UserContext, { PusherContext } from '../utils/getContext';
import {
    getLangs,
    getCurrencies,
    getUserRoles,
    getIsSponsor,
    getSettings,
    clearUserCache,
    getMenu,
    getUserInfo,
    getTokenByHash
} from '../utils/memo';
import {
    removeURLParameter,
    rolesMenu,
    SITE_URL,
    setLanguageForEmail,
    API_SERVER_URL,
    WSS_PORT,
    refreshChannelWS
} from '../utils/utils';
import {
    historyStore,
    getData,
    getTokenByIdAndHash,
    getAllResponses,
    dataSender
} from '../utils/router';
import { Uploader } from '../components/T';
import { zendoStorage } from '../../hybrid/wrappers';
import SeoData from '../../widgets/seoData/SeoData';
import Alert from '../../widgets/alert/Alert';
import CookieModal from '../../widgets/cookieModal/CookieModal';
import Router from 'next/router';
import Language from './Language';

const langs = require('../../scripts/configs/langs');
import Tooltips from './Tooltips';
import QuickNotificationsAlert from '../components/QuickNotificationsAlert';
import Pusher from 'pusher-js';


const UserContextComponent = (OriginalComponent) => class WrapperComponent extends React.Component {
    constructor(params) {
        super(params);

        this.localStorageChangeHandler = this.localStorageChangeHandler.bind(this);
        this.setPrevScene = this.setPrevScene.bind(this);
        this.activeScene = { url: 'Home', params: false };

        this.changeData = this.changeData.bind(this);
        this.setAlertData = this.setAlertData.bind(this);
        this.onRouteChangeStart = this.onRouteChangeStart.bind(this);
        this.onRouteChangeDone = this.onRouteChangeDone.bind(this);
        this.newToken = this.newToken.bind(this);
        this.projectTime = this.projectTime.bind(this);

        const props = params?.pageProps;
        const storage = params?.storageData;

        this.state = props?.file
            ? {}
            : {
                activeLangs: props?.activeLangs,
                userInfo: null,
                settings: props?.settings,
                seo: props?.seo,
                currencies: props?.currencies,
                menu: props?.menu,
                roles: [],
                lang: props?.lang || params?.router?.query?.lang || '',
                offset: props?.offset,
                //userToken: zendoStorage.get('userToken') || false,
                userToken: storage?.userToken || zendoStorage.get('userToken') || false,
                refer: false,
                tokenNumber: '0',
                position: '0',
                storageLoaded: false,
                notFound: params?.router?.asPath !== '/' && !params?.router?.query?.lang,
                routerLoader: false,
                domainInfo: null,
                alertData: false,
                pusherClient: null,
                intervalID: null,
                localization: zendoStorage.get('localization')
                    ? JSON.parse(zendoStorage.get('localization'))
                    : {}
            };
    }

    localStorageChangeHandler(e) {
        if (!e.key) {
            this.setState(state => (
                {
                    ...state,
                    userToken: zendoStorage.get('userToken')
                        ? zendoStorage.get('userToken')
                        : false,
                    refer: zendoStorage.get('refer') || false,
                    tokenNumber: zendoStorage.get('tokenNumber') || '0',
                    position: zendoStorage.get('position') || '0',
                    localization: zendoStorage.get('localization') || {}
                }
            ));
        } else if (['userToken', 'refer', 'localization', 'tokenNumber', 'position'].includes(e.key)) {
            this.setState(state => (
                {
                    ...state,
                    [e.key]: !!e.newValue
                        ? e.newValue
                        : e.key === 'localization'
                            ? {}
                            : false
                }
            ));
        }
    }

    setPrevScene(scene, params) {
        const oldScene = this.activeScene;
        if (this.activeScene.url !== scene || this.activeScene.params !== params) {
            this.activeScene = { url: scene, params: params };

            if (this.activeScene.url !== '/logout' && (this.state.prevUrl?.url !== oldScene.url || this.prevUrl?.params !== oldScene.params)) zendoStorage.set('prevUrlData', JSON.stringify(oldScene));
        }
    };

    async newToken(userToken, session) {
        clearUserCache();
        clearInterval(this.state.intervalID)
        if (userToken) {
            zendoStorage.set('userToken', userToken, session);
        } else {
            zendoStorage.remove('userToken');
        }
        const roles = userToken
            ? await getUserRoles(userToken)
            : [];
        const userInfo = userToken
            ? await getUserInfo(userToken)
            : null;
        this.setState({
            userToken, roles, userInfo
        });
    };

    async componentDidMount() {
        if (this.props.pageProps?.file || this.props.pageProps?.redirect) return;
        let {
            activeLangs, currencies, settings, offset, roles, lang, menu, userInfo
        } = { ...this.state };

        const searchParams = new URLSearchParams(window.location.search);
        const ByDomainOptions = {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                ...lang ? { 'Accept-Language': lang } : {}
                // Authorization: `Bearer ${userToken}`
            },
            body: JSON.stringify({ domain: process.env.NEXT_PUBLIC_SERVER_FRONT_URL })
        };
        let localization =
            this.state.localization ||
            (zendoStorage.get('localization') ? JSON.parse(zendoStorage.get('localization')) : false);
        const localizationCopy = { ...localization || {} };

        if (process.env.NEXT_PUBLIC_SERVER_FRONT_URL !== process.env.NEXT_PUBLIC_MAIN_SERVER_FRONT_URL) {
            try {
                const response = await fetch(`${API_SERVER_URL}/api/v1/shop/shop/by-domain`, ByDomainOptions);
                const previewData = await response?.json(); // Попередній перегляд повертається у форматі JSON


                if (previewData?.data?.id && previewData?.data?.status !== 'hidden'){
                    zendoStorage.set('shopId', previewData?.data?.id);
                    this.setState({ domainInfo: previewData?.data })
                }
                // Встановлення значень для localizationCopy.country з отриманих даних
                if (previewData?.data?.status === 'hidden'){
                    document.location.assign(`${process.env.NEXT_PUBLIC_MAIN_SERVER_FRONT_URL}`)
                }
            // Показати попередній перегляд в UI (зробіть це, якщо необхідно)
            // displayPreview(previewData);
            } catch (error) {
                console.error('Помилка при отриманні даних за доменом:', error);
            // Обробка помилок, якщо щось пішло не так під час виконання запиту
            }
        }

        const userToken = this.state.userToken || zendoStorage.get('userToken');
        const hash = searchParams.get('hash');
        const kzState = searchParams.get('kzState')
        const country_code = searchParams.get('country')
        const country_id = searchParams.get('country_id')

        if (userToken) {
            this.setState({ userToken, storageLoaded: true });
        } else {
            this.setState({ storageLoaded: true });
        }


        const params = this.getDomenData(window?.location?.href);
        const refer = params?.ref || zendoStorage.get('refer');
        const tokenNumber = params?.tokenNumber || zendoStorage.get('tokenNumber');
        const position = params?.position || zendoStorage.get('position');

        if (refer && lang) {
            try {
                const dataValidReferalReq = await getIsSponsor(userToken, refer);

                if (!dataValidReferalReq || dataValidReferalReq?.errors) {
                    if (params?.ref) {
                        return document.location.assign(`${SITE_URL}/${lang}/error/refer`);
                    } else {
                        this.setState({ refer: false });
                        zendoStorage.remove('refer');
                    }
                } else {
                    this.setState({ refer });
                    zendoStorage.set('refer', refer);
                    if (tokenNumber) {
                        this.setState({ tokenNumber });
                        zendoStorage.set('tokenNumber', tokenNumber);
                        this.setState({ position });
                        zendoStorage.set('position', position);
                    }
                    if (params?.ref) {
                        if (params?.tokenNumber) {
                            const domain = SITE_URL.replace('http://', '').replace('https://', '');
                            const redirectUrl = removeURLParameter(window.location.href.replace('http://', '').replace('https://', '')
                                .replace(domain, ''), 'ref');
                            const redirectUrlToken = removeURLParameter(redirectUrl, 'tokenNumber');
                            const redirectUrlLast = removeURLParameter(redirectUrlToken, 'position');
                            return document.location.assign(`${SITE_URL}${redirectUrlLast}`);
                        } else {
                            const domain = SITE_URL.replace('http://', '').replace('https://', '');
                            const redirectUrl = removeURLParameter(window.location.href.replace('http://', '').replace('https://', '')
                                .replace(domain, ''), 'ref');

                            return document.location.assign(`${SITE_URL}${redirectUrl}`);
                        }
                    }
                }
            } catch {
                if (params?.ref) {
                    return document.location.assign(`${SITE_URL}/${lang}/error/refer`);
                } else {
                    this.setState({ refer: false });
                    zendoStorage.remove('refer');
                    if (params?.tokenNumber) {
                        return document.location.assign(`${SITE_URL}/${lang}/error/tokenNumber`);
                    } else {
                        this.setState({ tokenNumber: '0' });
                        zendoStorage.remove('tokenNumber');
                        this.setState({ position: '0' });
                        zendoStorage.remove('position');
                    }
                }
            }
        } else if (params?.id && params?.hash && lang) {
            try {
                const dataValidHash = await getTokenByIdAndHash(params?.id, params?.hash);
                if (!dataValidHash) {
                    return document.location.assign(`${SITE_URL}/${lang}/error/403`);
                } else {
                    await this.newToken(dataValidHash);
                    if (params?.id && params?.hash) {
                        return document.location.assign(`${SITE_URL}/${lang}/office`);
                    }
                }
            } catch {
                return document.location.assign(`${SITE_URL}/${lang}/error/403`);
            }
        }

        window.addEventListener('storage', this.localStorageChangeHandler);
        historyStore('on');

        Router.router.events.on('routeChangeStart', this.onRouteChangeStart);
        Router.router.events.on('routeChangeComplete', this.onRouteChangeDone);
        Router.router.events.on('routeChangeError', this.onRouteChangeDone);


        if (!activeLangs) {
            activeLangs = await getLangs();
            this.setState({ activeLangs });
        }

        let isActiveLang, sliceLenght, actualLang;
        if (!lang) {
            sliceLenght = this.props.router.asPath.length > 3
                ? 4
                : 3;
            actualLang = this.props.router.asPath.slice(0, sliceLenght);
            isActiveLang = activeLangs?.find((l) => `/${l.alias}${sliceLenght === 3
                ? ''
                : '/'}` === actualLang);
            if (isActiveLang) {
                lang = isActiveLang.alias;
                this.setState({ notFound: false, lang });
            }
        }

        if (!currencies && lang) {
            currencies = await getCurrencies(lang, userToken);
            this.setState({ currencies });
        }
        if (this.state.domainInfo && localizationCopy?.country?.id !== this.state.domainInfo?.country?.geoname_id){
            localizationCopy.country = {
                id: this.state.domainInfo?.country?.geoname_id,
                code: this.state.domainInfo?.country?.country
            };
            localizationCopy.currency = this.state.domainInfo?.finance_currency?.code
        } else if (kzState && process.env.NEXT_PUBLIC_SERVER_FRONT_URL === process.env.NEXT_PUBLIC_MAIN_SERVER_FRONT_URL){
            // const data = getGeoName('1522867', lang)
            localizationCopy.country = {
                id: 1522867,
                code: 'KZ'
            };
            localizationCopy.currency = 'KZT'
        } else if (country_code && country_id && process.env.NEXT_PUBLIC_SERVER_FRONT_URL === process.env.NEXT_PUBLIC_MAIN_SERVER_FRONT_URL){
            // const data = getGeoName('1522867', lang)
            localizationCopy.country = {
                id: country_id,
                code: country_code
            };
            localizationCopy.currency = 'AD'
        } else if (!localizationCopy?.country?.id && process.env.NEXT_PUBLIC_SERVER_FRONT_URL === process.env.NEXT_PUBLIC_MAIN_SERVER_FRONT_URL) {
            let localizationData = await getData('/api/v1/geonames/country-by-ip', userToken, lang) || {};
            localizationCopy.country = {
                id: localizationData?.data?.geoname_id,
                code: localizationData?.data?.country
            };
            const localCurrency = currencies?.data?.find((el) => el.code === localizationData?.data?.currency);
            const localLang = activeLangs?.find((el) => el.alias === localizationData?.data?.language);

            if (localCurrency && !localizationCopy.currency) {
                localizationCopy.currency = localCurrency.code;
            } else if (localizationCopy.currency) {
                const isActiveCurrency = currencies?.data?.find((el) => el.code === localizationCopy.currency);
                if (!isActiveCurrency) {
                    if (localCurrency) {
                        localizationCopy.currency = localCurrency.code;
                    } else {
                        localizationCopy.currency = false;
                    }
                }
            }
            if (localLang && !localizationCopy.language && (!lang || this.props.router.asPath === '/')) {
                localizationCopy.language = localLang.alias;
            }
        }

        if (!localizationCopy.currency) {
            localizationCopy.currency = currencies?.data?.find((el) => el.main === true)?.code || 'USD';
        } else {
            const isActiveCurrency = currencies?.data?.find((el) => el.code === localizationCopy.currency);
            if (!isActiveCurrency) {
                localizationCopy.currency = currencies?.data?.find((el) => el.main === true)?.code || 'USD';
            }
        }

        if (!lang && !isActiveLang || this.props.router.asPath === '/') {
            const prevLang = activeLangs?.find((l) => localizationCopy.language === l.alias);
            const defaultLang = activeLangs?.find((l) => l.isDefault);

            lang = prevLang?.alias || defaultLang?.alias || 'ru';

            const isLang = langs?.find((l) => `/${l}${sliceLenght === 3
                ? ''
                : '/'}` === actualLang);
            if (isLang) {
                const actualPath = this.props.router.asPath.slice(sliceLenght);
                return document.location.assign(`${SITE_URL}/${lang}/${actualPath}`);
            } else if (this.props.router.asPath !== '/') {
                return document.location.assign(`${SITE_URL}/${lang}${this.props.router.asPath}`);
            }
        }
        localizationCopy.language = lang;

        this.setState({ localization: localizationCopy });
        zendoStorage.set('localization', JSON.stringify(localizationCopy))

        let settingsReq = null
        let seoReq= null
        let timeResReq = null
        let rolesReq = null
        let siteReq = null
        let footerReq = null
        let officeReq = null
        let adminReq = null

        if (!settings) {
            settingsReq = new Promise((resolve) => {
                getSettings('display').then((data) => resolve({ data, alias: 'settings' }));
            })
        }
        if (!this.state.seo) {
            seoReq = new Promise((resolve) => {
                getSettings('seo').then((data) => resolve({ data, alias: 'seo' }));
            })
        }
        if (!offset) {
            timeResReq = new Promise((resolve) => {
                getData('/api/v1/settings/date', false, lang).then((data) => resolve({ data, alias: 'timeRes' }));
            })
        }

        if (userToken && !roles.length) {
            rolesReq = new Promise((resolve) => {
                getUserRoles(userToken, lang).then((data) => resolve({ data, alias: 'rolesRes' }));
            })
        }
        if (!menu) {
            siteReq = new Promise((resolve) => {
                getMenu('site').then((data) => resolve({ data, alias: 'site' }));
            })
            footerReq = new Promise((resolve) => {
                getMenu('footer').then((data) => resolve({ data, alias: 'footer' }));
            })
            officeReq = new Promise((resolve) => {
                getMenu('office').then((data) => resolve({ data, alias: 'office' }));
            })
            adminReq = new Promise((resolve) => {
                getMenu('admin').then((data) => resolve({ data, alias: 'admin' }));
            })
        }
        const allPromises = [settingsReq, seoReq, timeResReq, rolesReq, siteReq, adminReq, footerReq, officeReq].filter(item => !!item)
        const res = await getAllResponses(allPromises)
        if (!settings) {
            this.setState({ settings: res?.settings });
        }
        if (!this.state.seo) {
            this.setState({ seo: res?.seo });
        }
        if (!offset) {
            let projectTimeZone = 'Asia/Aqtau';
            // if (this.state.settings?.timezone) {
            //     projectTimeZone = this.state.settings?.timezone.split(' ')[0];
            // }
            const time = moment.tz(res?.timeRes?.data?.date, 'YYYY-MM-DD HH:mm:ss', projectTimeZone);
            const newOffset = time.diff(moment());
            this.setState({ offset: newOffset });
        }

        if (userToken && !roles.length) {
            this.setState({ roles: res?.rolesRes });
        }
        if (!menu) {
            let newMenu = {
                ...res?.site, ...res?.footer, ...res?.admin, ...res?.office
            };
            this.setState({ menu: newMenu });
        }
        if (userToken && !userInfo) {
            const data = await getUserInfo(userToken, lang);
            this.setState({ userInfo: data });
        }
        if (hash && !zendoStorage.get('userToken')){
            const userByHashToken = await getTokenByHash(hash)
            if (userByHashToken?.['auth-key']){
                zendoStorage.set('userToken', userByHashToken['auth-key'])
                window.location.reload()
            }
        } else if (hash) {
            const url = new URL(window.location.href);
            // Оновлюємо URL без параметра hash
            const newUrl = `${url.origin}${url.pathname}`;
            // window.history.replaceState(null, '', newUrl);
            window.history.replaceState({
                ...window.history.state, as: newUrl, url: newUrl
            }, '', newUrl);
        }
        if (userToken){
            const apiUrl = new URL(API_SERVER_URL);
            const optionsPusher = {
                key: 'key',
                dev: process.env.NODE_ENV === 'development',
                cluster: 'mt1',
                forceTLS: false,
                enabledTransports: ['ws', 'wss'],
                wsHost: apiUrl.host,
                wsPort: WSS_PORT,
                wssPort: WSS_PORT,
                scheme: apiUrl.protocol,
                authorizer: (channel) => {
                    return {
                        authorize: (socketId, callback) => {
                            return dataSender(
                                `${API_SERVER_URL}/broadcasting/auth`,
                                'POST',
                                { socket_id: socketId, channel_name: channel.name },
                                (data) => {
                                    callback(false, data.data);
                                },
                                () => {},
                                userToken,
                                lang
                            );
                        }
                    };
                }
            };
            let pusherClient = WSS_PORT && userToken ? new Pusher(optionsPusher.key, optionsPusher) : null
            this.setState({ pusherClient })
            if (WSS_PORT && userToken) {
                window.Pusher = Pusher;
                if (process.env.NODE_ENV === 'development') {
                    Pusher.logToConsole = true;
                }
            }
            this.setState({ intervalID: setInterval(() => refreshChannelWS(this.state.userToken), 270000) })
        }
    }
    async componentDidUpdate(_, prevState){
        if (this.state.userToken !== prevState.userToken){
            clearInterval(this.state.intervalID)
            if (!!this.state.userToken){
                this.setState({ intervalID: setInterval(() => refreshChannelWS(this.state.userToken), 270000) })
                const apiUrl = new URL(API_SERVER_URL);
                const optionsPusher = {
                    key: 'key',
                    dev: process.env.NODE_ENV === 'development',
                    cluster: 'mt1',
                    forceTLS: false,
                    enabledTransports: ['ws', 'wss'],
                    wsHost: apiUrl.host,
                    wsPort: WSS_PORT,
                    wssPort: WSS_PORT,
                    scheme: apiUrl.protocol,
                    authorizer: (channel) => {
                        return {
                            authorize: (socketId, callback) => {
                                return dataSender(
                                    `${API_SERVER_URL}/broadcasting/auth`,
                                    'POST',
                                    { socket_id: socketId, channel_name: channel.name },
                                    (data) => {
                                        callback(false, data.data);
                                    },
                                    () => {},
                                    this.state.userToken,
                                    this.state.lang
                                );
                            }
                        };
                    }
                };
                let pusherClient = WSS_PORT && this.state.userToken ? new Pusher(optionsPusher.key, optionsPusher) : null
                this.setState({ pusherClient })
                if (WSS_PORT && this.state.userToken) {
                    window.Pusher = Pusher;
                    if (process.env.NODE_ENV === 'development') {
                        Pusher.logToConsole = true;
                    }
                }
            } else {
                window.Pusher = null;
                this.setState({ pusherClient: null })
            }
        }
    }

    componentWillUnmount() {
        historyStore('off');

        window.removeEventListener('storage', this.localStorageChangeHandler);
        Router.router.events.off('routeChangeStart', this.onRouteChangeStart);
        Router.router.events.off('routeChangeComplete', this.onRouteChangeDone);
        Router.router.events.off('routeChangeError', this.onRouteChangeDone);
        clearInterval(this.state.intervalID)
    }

    setAlertData(data) {
        (!!data?.cont || typeof data === 'boolean') && this.setState({ alertData: data });
    }

    onRouteChangeStart(url) {
        this.setState({ routerLoader: { from: Router.router.asPath, to: url } });
    }

    onRouteChangeDone() {
        window.scrollTo(0, 0);
        this.setState({ routerLoader: false });
    }

    onRouteChangeStart() {
        this.setState({ routerLoader: true });
    }

    onRouteChangeDone() {
        this.setState({ routerLoader: false });
    }

    projectTime() {
        return moment().add(this.state.offset);
    }

    getDomenData(href) {
        try {
            if (!href) return false;
            const url = new URL(href);
            const searchParams = new URLSearchParams(url.search);
            return {
                ref: searchParams.get('ref'),
                id: searchParams.get('id'),
                hash: searchParams.get('hash'),
                tokenNumber: searchParams.get('tokenNumber'),
                position: searchParams.get('position')
            };
        } catch (e) {
            return false;
        }
    }

    async changeData(key, data) {
        this.setState({ [key]: data });
        if (key === 'lang') {
            const oldLang = this.state.lang;
            const newLang = data;
            if (oldLang !== newLang) {
                const route = this.props.router.asPath === '/'
                    ? '/[lang]'
                    : this.props.router.route;
                const asPath = `/${newLang}${this.props.router.asPath.replace(`/${oldLang}${this.props.router.asPath === `/${oldLang}`
                    ? ''
                    : '/'}`, '/')}`;

                this.props.router.push(route, asPath);
            }
            if (this.state.userToken) {
                setLanguageForEmail(this.state.userToken, newLang);
            }
        } else if (key === 'localization') {
            zendoStorage.set('localization', JSON.stringify(data));
        }
    }

    render() {
        const params = this.getDomenData(`${SITE_URL}${this.props.router.asPath}`) || {};
        if (params?.ref || params?.id && params?.hash || this.state.notFound) return null;

        const {
            seo, activeLangs, menu, roles, pusherClient, userToken
        } = this.state;
        const { translates } = this.props.pageProps || {};

        const global = {
            ...this.state,
            activeLangs: activeLangs || [],
            menu: rolesMenu(menu, roles) || {},
            time: this.projectTime,
            setUserToken: this.newToken,
            setAlertData: this.setAlertData,
            //setState: this.changeDatarouterLoader
            setState: this.changeData
        };
        const currPageName = this.props.router.asPath.substring(this.props.router.asPath.lastIndexOf('/') + 1);
        const defaultSeo = seo?.find((s) => s.alias === currPageName) || seo?.find((s) => s.alias === 'default') || null;
        return (
            <PusherContext.Provider value={{ pusherClient }}>
                <UserContext.Provider value={global}>

                    <Language page='alert'>
                        <Language page='all' translates={translates}>
                            <SeoData lang={this.state.lang} data={defaultSeo}/>
                            {this.state.storageLoaded || !this.props.pageProps.protect
                                ? <Tooltips>
                                    <Uploader/>
                                    <Alert/>
                                    <OriginalComponent {...this.props} />
                                    <CookieModal/>
                                    {userToken && < QuickNotificationsAlert /> }
                                </Tooltips>
                                : null}
                        </Language>
                    </Language>

                </UserContext.Provider>
            </PusherContext.Provider>
        );
    }
};
export default UserContextComponent;